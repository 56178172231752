require('./bootstrap');

const axios = require('axios').default;
import Alpine from 'alpinejs';
window.Alpine = Alpine;

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
window.tippy = tippy;

document.addEventListener('alpine:init', () => {
	Alpine.data('content', () => ({
		openPopup: false,
		loading: false,
		references: [],
		selectedReferences: [],
		
		init() {
			this.$watch('openPopup', isOpen => {
				document.body.classList.toggle('no-scroll', isOpen);


				if(!isOpen){
					// Clear popup contents on close
					this.selectedReferences = [];
					popup.querySelector('.popup__content .img-wrapper')?.remove();
					popup.querySelector('.map-wrapper')?.remove();
					popup.querySelector('iframe')?.remove();
				}
			})

			const el = this.$refs.el;

			/**
			 * --- REFERENCES
			 */
			const referenceLinks = [...el.querySelectorAll('a')]?.filter(link => link.getAttribute('href').startsWith('data='));

			for (const link of referenceLinks) {
				let commaIds = link.getAttribute('href').replace('data=', '');	// '1,2,3'
			
				// Create span tag with link contents
				let span = document.createElement('u');
				span.innerHTML = link.innerHTML;

				// Remove link tag
				link.parentNode.insertBefore(span, link);
				link.remove();

				span.onclick = () => this.getReferences(commaIds);
			}

			/**
			 * --- POPUP IMGS
			 */
			const popup = this.$refs.popup;
			// Imgs link wrapper (external link) will be opened using the popup
			const previewImgs = el.querySelectorAll('a > img');

			for (const previewImg of previewImgs) {
				let parentLink = previewImg.parentElement; // <a>

				let href = parentLink.getAttribute('href');
				
				// Div to replace link tag
				let div = document.createElement('div');
				div.append(previewImg);
				div.onclick = () => {
					let img = document.createElement('img');
					img.src = href;

					// Place image in popup
					let imgWrapper = document.createElement('div');
					imgWrapper.classList.add('img-wrapper');
					imgWrapper.appendChild(img);
					popup.querySelector('.popup__content').appendChild(imgWrapper);

					this.openPopup = true;
				};
				parentLink.parentElement.appendChild(div);
				parentLink.remove();

			}

			const imgReferencesLink = [...document.querySelectorAll('a')]?.filter(link => link.href.includes('/uploads/'));
			for (const linkTag of imgReferencesLink) {
				linkTag.onclick = e => {
					e.preventDefault();
					let img = document.createElement('img');
					img.src = linkTag.href;

					// Place image in popup
					let imgWrapper = document.createElement('div');
					imgWrapper.classList.add('img-wrapper');
					imgWrapper.appendChild(img);
					popup.querySelector('.popup__content').appendChild(imgWrapper);
					
					this.openPopup = true;
				}
			}


			/**
			 * --- YT VIDEO POPUP
			 */
			const ytLinks = [...document.querySelectorAll('a')]?.filter(link => link.href.includes('youtube') || link.href.includes('youtu.be'));
			for(const link of ytLinks){
				link.onclick = e => {
					e.preventDefault();
					this.loading = true;
					this.openPopup = true;
					fetch(`https://www.youtube.com/oembed?url=${link.href}&format=json`)
						.then(async response => {
							let data = await response.json();
							popup.querySelector('.popup__content').insertAdjacentHTML('beforeend', data.html);
							this.loading = false;
						});
				}
			}
		},
		
		async getReferences(commaIds){
			this.openPopup = true;
			this.loading = true;
			this.selectedReferences = [];

			// [1,2,3]
			let idsArray = Array.from(commaIds.split(','), Number);
						
			// Get already loaded references
			const loadedReferences = this.references.filter(reference => idsArray.includes(reference.id));			
			// Filter ids of references pending to fetched
			const idsToFetch = idsArray.filter(id => loadedReferences.filter(ref => ref.id == id).length === 0);
			
			if (idsToFetch.length == 0){
				// If no ids need to be fetched
				this.selectedReferences.push(...loadedReferences);
			} else {
				// Fetch for new refs
				try {
					const response = await axios.get(`/references/find?ids=${idsToFetch.join(',')}`);
	
					// Save new references
					this.references.push(...response.data);
					
					// Update modal with requested references
					this.selectedReferences.push(...response.data);
					this.selectedReferences.push(...loadedReferences);
	
				} catch (e) {
					this.openPopup = false
					alert('Ha ocurrido un error');
				}
			}

			// Order by index
			this.selectedReferences.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));

			this.loading = false;
		},

		showMap() {
			const popup = this.$refs.popup;
			const map = this.$refs.map;
			const mapWrapper = map.querySelector('.map-wrapper');
			popup.querySelector('.popup__content').appendChild(mapWrapper.cloneNode(true));
			this.openPopup = true;
		}

	})
	);
});

Alpine.start();